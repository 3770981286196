<template>
  <div
    class="vacancy__folder__parents"
    ref="parents"
    :class="{
      vacancy__folder__parents__active: vacancyActive,
      vacancy__folder__parents__notActive: !vacancyActive
    }"
  >
    <PerfectScrollbar
      class="vacancy__folder__parents__scroll-body"
      :options="{ minScrollbarLength: 173, suppressScrollX: true }"
      ref="parents"
      infinite-wrapper
    >
      <div class="dotted-line-verticle" ref="dottedLine"></div>
      <template v-for="(vacancy, index) in vacancies">
        <div :key="index">
          <div class="vacancy__parent" @click="setActiveVacancy(vacancy.id)">
            <div class="vacancy__parent__icon">
              <img :src="vacancy.parent.icon" />
            </div>
            <div class="vacancy__parent__text">
              <div class="vacancy__parent__text__header">
                {{ vacancy.parent.name + " " + vacancy.parent.surname }}
              </div>
              <div class="vacancy__parent__text__description">
                {{ vacancy.parent.grade }} ищет <br />
                {{ vacancy.grade }}
              </div>
            </div>
          </div>
          <h4
            class="dotted-line-vacancy"
            v-if="index < vacancies.length - 1"
          ></h4>
        </div>
      </template>
    </PerfectScrollbar>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import axios from "axios";

export default {
  name: "vacancyParents",
  components: { PerfectScrollbar },
  props: {
    vacancyActive: Boolean
  },
  mounted() {
    this.setVacancies();
    this.calculateHeight();
  },
  methods: {
    setVacancies() {
      axios.get("/vacancies").then(res => {
        this.vacancies = res.data.data.payload;
      });
    },
    setActiveVacancy(vacancyId) {
      let vacancy = this.vacancies.find(item => item.id === vacancyId);
      this.$emit("setVacancy", vacancy);
    },
    calculateHeight() {
      let dottedLine = this.$refs.dottedLine;
      dottedLine.style.width = this.$refs.parents.clientHeight;
    }
  },
  data() {
    return {
      vacancies: {}
    };
  }
};
</script>
