<template>
  <draggable
    ghostClass="folder--ghost"
    :group="{ name: 'folder-' + folderId, pull: false, put: false }"
    :disabled="!isDraggable"
    handle=".folder__handle"
    :forceFallback="true"
    :fallbackTolerance="10"
    @start="dragstart"
    @end="dragend"
  >
    <div
      class="folder"
      :class="['folder--' + folderId]"
      :style="{
        top: position.y + 'px',
        left: position.x + 'px',
        zIndex: folders.indexOf(folderId) + 50
      }"
      @click="click"
    >
      <div class="folder__handle"></div>
      <button
        v-if="hasBack"
        class="folder__back"
        @click="$emit('layerBack', folderId, back)"
      >
        <img :src="BackIcon" />
      </button>
      <div
        class="folder__header"
        v-if="hasHeader"
        :class="{ folder__header__left__padding: hasBack }"
      >
        {{ header }}
      </div>
      <button
        class="folder__close"
        @click="$emit('layerClose', folderId)"
      ></button>

      <PerfectScrollbar
        class="folder__scroll-body"
        :options="{ minScrollbarLength: 173, suppressScrollX: true }"
        :class="{
          'folder__scroll-body--no-header': !hasHeader,
          'folder__scroll-body--no-footer': !hasFooter
        }"
        infinite-wrapper
      >
        <div class="folder__description" v-if="description">
          {{ description }}
        </div>
        <slot name="content" />
      </PerfectScrollbar>
      <div class="folder__footer" v-if="hasFooter">
        <button
          v-if="folderId === 'trash'"
          class="folder__btn"
          @click="$emit('folderFooterClick', folderId)"
        >
          {{ $t("trash-reset") }}
        </button>
      </div>
    </div>
  </draggable>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import draggable from "vuedraggable";
import BackIcon from "@/assets/images/icons/Back.svg";
export default {
  name: "Folder",
  components: { PerfectScrollbar, draggable },
  props: {
    header: String,
    back: String,
    description: String,
    folderType: String,
    folderId: String,
    folders: Array,
    isDraggable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dragStartPosition: {
        x: 0,
        y: 0
      },
      position: {
        x: 0,
        y: 0
      },
      BackIcon
    };
  },
  computed: {
    hasBack() {
      return this.back;
    },
    hasHeader() {
      return this.header && this.header.length > 0;
    },
    hasFooter() {
      return this.folderId === "trash";
    }
  },
  created() {
    this.$nextTick(() => {
      this.position = this.getDefaultPosition();
    });
  },
  methods: {
    getDefaultPosition() {
      const {
        clientWidth: width,
        clientHeight: height
      } = this.$el.querySelector(".folder");
      const { folders } = this;

      return {
        y: window.innerHeight / 2 - height / 2 - folders.length * 40,
        x: window.innerWidth / 2 - width / 2 - folders.length * 40
      };
    },
    dragstart(event) {
      this.dragStartPosition = {
        x: event.originalEvent.clientX,
        y: event.originalEvent.clientY
      };
    },
    dragend(event) {
      const { position, dragStartPosition } = this;

      this.$nextTick(() => {
        this.position = {
          x: position.x + event.originalEvent.clientX - dragStartPosition.x,
          y: position.y + event.originalEvent.clientY - dragStartPosition.y
        };
      });

      this.$emit("layerUp", this.folderId);
    },
    click() {
      this.$emit("layerUp", this.folderId);
    }
  }
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
