var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{attrs:{"ghostClass":"folder--ghost","group":{ name: 'folder-' + _vm.folderId, pull: false, put: false },"disabled":!_vm.isDraggable,"handle":".folder__handle","forceFallback":true,"fallbackTolerance":10},on:{"start":_vm.dragstart,"end":_vm.dragend}},[_c('div',{staticClass:"folder",class:['folder--' + _vm.folderId],style:({
      top: _vm.position.y + 'px',
      left: _vm.position.x + 'px',
      zIndex: _vm.folders.indexOf(_vm.folderId) + 50
    }),on:{"click":_vm.click}},[_c('div',{staticClass:"folder__handle"}),(_vm.hasBack)?_c('button',{staticClass:"folder__back",on:{"click":function($event){return _vm.$emit('layerBack', _vm.folderId, _vm.back)}}},[_c('img',{attrs:{"src":_vm.BackIcon}})]):_vm._e(),(_vm.hasHeader)?_c('div',{staticClass:"folder__header",class:{ folder__header__left__padding: _vm.hasBack }},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),_c('button',{staticClass:"folder__close",on:{"click":function($event){return _vm.$emit('layerClose', _vm.folderId)}}}),_c('PerfectScrollbar',{staticClass:"folder__scroll-body",class:{
        'folder__scroll-body--no-header': !_vm.hasHeader,
        'folder__scroll-body--no-footer': !_vm.hasFooter
      },attrs:{"options":{ minScrollbarLength: 173, suppressScrollX: true },"infinite-wrapper":""}},[(_vm.description)?_c('div',{staticClass:"folder__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_vm._t("content")],2),(_vm.hasFooter)?_c('div',{staticClass:"folder__footer"},[(_vm.folderId === 'trash')?_c('button',{staticClass:"folder__btn",on:{"click":function($event){return _vm.$emit('folderFooterClick', _vm.folderId)}}},[_vm._v(" "+_vm._s(_vm.$t("trash-reset"))+" ")]):_vm._e()]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }