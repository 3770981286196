<template>
  <div class="home">
    <Homescreen :theme="theme" :customWallpaperUrl="customWallpaperUrl">
      <template v-slot:content>
        <draggable
          v-model="icons.homescreen"
          :group="{ name: 'group-home', pull: true, put: true }"
          ghostClass="shortcut--ghost"
          handle=".shortcut__handle"
          :sort="false"
          :forceFallback="true"
          :fallbackTolerance="10"
          :disabled="!isDraggable"
          :move="checkMove"
          @choose="dragstart"
          @end="dragend"
        >
          <transition-group name="no" class="homescreen__list-group" tag="div">
            <div
              v-for="icon in icons.homescreen"
              :key="icon.id"
              class="shortcut__holder"
              :class="'shortcut__holder--' + icon.id"
              :data-id="icon.id"
            >
              <Shortcut
                :text="getTranslation(icon.title)"
                :shortcutId="icon.id"
                :type="icon.type"
                :icons="icons.homescreen"
                :icon="icon.icon"
                :iconHover="icon.iconHover"
                :link="icon.link"
                :topShortcut="topShortcut"
                :list="icons[icon.id]"
                :defaultPosition="iconsPositionsHolder[icon.id]"
                @dblclick="shortcutClick(icon.id), folderUp(icon.id, icon.type)"
                @layerUp="shortcutUp"
                @updatePosition="updateIconPosition"
                @sendShortcutMove="sendShortcutMove"
              />
            </div>
          </transition-group>
        </draggable>

        <template v-for="folder in iconFoldersList">
          <Folder
            :key="folder.id"
            :header="getTranslation(folder.title)"
            :folderId="folder.id"
            v-if="folders.indexOf(folder.id) != -1"
            :folders="folders"
            :back="folder.back"
            :folderType="folder.type"
            :isDraggable="isDraggable"
            @layerClose="folderClose"
            @layerUp="folderUp"
            @layerBack="folderBack"
            @folderFooterClick="folderFooterClick"
          >
            <template v-slot:content>
              <draggable
                v-model="icons[folder.id]"
                :group="{
                  name: 'group-folder-' + folder.id,
                  pull: true,
                  put: true
                }"
                ghostClass="shortcut--ghost"
                handle=".shortcut__handle"
                :forceFallback="true"
                :fallbackTolerance="10"
                fallbackClass="shortcut--fallback"
                :disabled="!isDraggable"
                :move="checkMove"
                @remove="remove"
                @change="change"
              >
                <transition-group
                  name="no"
                  :class="'folder__icons folder__icons--' + folder.id"
                >
                  <Shortcut
                    v-for="icon in icons[folder.id]"
                    :key="icon.id"
                    :text="getTranslation(icon.title)"
                    :type="icon.type"
                    :shortcutId="icon.id"
                    :list="icons[icon.id]"
                    :icon="icon.icon"
                    :iconHover="icon.iconHover"
                    :link="icon.link"
                    :back="folder.id"
                    @dblclick="folderUp(icon.id, icon.type)"
                    @layerBack="folderBack"
                    @updatePosition="updateIconPosition"
                  />
                </transition-group>
              </draggable>
            </template>
          </Folder>
        </template>

        <Folder
          :header="$t('icons.terminal')"
          folderId="terminal"
          v-if="folders.indexOf('terminal') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Terminal @layerUp="folderUp" />
          </template>
        </Folder>

        <Folder
          :header="$t('icons.contacts')"
          folderId="contacts"
          v-if="folders.indexOf('contacts') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Contacts />
          </template>
        </Folder>

        <Folder
          :header="$t('icons.paint')"
          folderId="paint"
          v-if="folders.indexOf('paint') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Draw />
          </template>
        </Folder>

        <Folder
          :header="$t('icons.sapper')"
          folderId="sapper"
          v-if="folders.indexOf('sapper') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content><Sapper /></template>
        </Folder>

        <Folder
          folderId="order"
          v-if="folders.indexOf('order') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Form />
          </template>
        </Folder>

        <Folder
          :header="$t('icons.gallery')"
          folderId="gallery"
          v-if="folders.indexOf('gallery') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Gallery />
          </template>
        </Folder>

        <VacancyFolder
          header="vacancies"
          folderId="vacancies"
          v-if="folders.indexOf('vacancies') != -1"
          :isDraggable="isDraggable"
          :folders="folders"
          @layerClose="folderClose"
          @layerUp="folderUp"
          @layerBack="folderBack"
        >
        </VacancyFolder>

        <template v-for="project in projects">
          <Folder
            :key="project.id"
            :folderId="'project-' + String(project.id)"
            v-if="folders.indexOf('project-' + String(project.id)) != -1"
            :header="getTranslation(project.title)"
            :back="project.back"
            :folders="folders"
            :isDraggable="isDraggable"
            @layerClose="folderClose"
            @layerUp="folderUp"
            @layerBack="folderBack"
          >
            <template v-slot:content>
              <ProjectNew :project="project" />
            </template>
          </Folder>
        </template>

        <Folder
          :header="$t('icons.portfolio')"
          folderId="portfolio"
          v-if="folders.indexOf('portfolio') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          :description="$t('portfolio.header')"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Clients @layerBack="folderBack" />
          </template>
        </Folder>

        <Folder
          :header="$t('icons.awards')"
          folderId="awards"
          v-if="folders.indexOf('awards') != -1"
          :folders="folders"
          :isDraggable="isDraggable"
          @layerClose="folderClose"
          @layerUp="folderUp"
        >
          <template v-slot:content>
            <Awards @layerBack="folderBack" />
          </template>
        </Folder>

        <template v-for="award in awards">
          <Folder
            :key="award.id"
            :folderId="'award-' + String(award.id)"
            v-if="folders.indexOf('award-' + String(award.id)) != -1"
            :header="getTranslation(award.title)"
            :back="'awards'"
            :folders="folders"
            :isDraggable="isDraggable"
            @layerClose="folderClose"
            @layerBack="folderBack"
            @layerUp="folderUp"
          >
            <template v-slot:content>
              <Award :image="award.image" />
            </template>
          </Folder>
        </template>
        <Cookie />
      </template>
    </Homescreen>
    <Taskbar
      :theme="theme"
      :darkmode="darkmode"
      @showForm="folderUp('order')"
      @toggleMode="toggleMode"
    />

    <Screensaver />

    <audio ref="trashAudio">
      <source src="/trash.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
import Homescreen from "@/components/Homescreen";
import Taskbar from "@/components/Taskbar";
import Shortcut from "@/components/Shortcut";
import Folder from "@/components/Folder";
import Contacts from "@/components/Contacts";
import Form from "@/components/Form";
import Terminal from "@/components/Terminal";
import Draw from "@/components/Draw";
import Sapper from "@/components/Sapper";
import Screensaver from "@/components/Screensaver";
import VacancyFolder from "@/components/Vacancies/VacancyFolder";
import Gallery from "@/components/Gallery";
import Clients from "@/components/Clients";
import Awards from "@/components/Awards";
import ProjectNew from "@/components/ProjectNew";
import Cookie from "@/components/Cookie";
import Award from "@/components/Award";

import VacancyIcon from "@/assets/images/vacancy.svg";

import draggable from "vuedraggable";
import axios from "axios";

const setIconPosition = ({ top, left, right, bottom }) => {
  const footerHeight = 50;
  const iconWidth = 160;
  let space = 20;
  let cellSize = 200;

  if (window.innerWidth < 1360 || window.innerHeight < 840) {
    space = 10;
    cellSize = 160;
  }

  let x = space;
  let y = space;

  if (top !== undefined) {
    y = top * cellSize + space;
  } else if (bottom !== undefined) {
    y = window.innerHeight - ((bottom + 1) * cellSize + footerHeight);
  } else {
    // console.log("ни top ни bottom не указаны, x = space");
  }

  if (left !== undefined) {
    x = left * cellSize + space;
  } else if (right !== undefined) {
    x = window.innerWidth - (right * cellSize + iconWidth + space);
  } else {
    // console.log("ни left ни right не указаны, y = space");
  }

  return { x, y };
};

const getDefaultIconsPositions = preset => {
  if (preset == "desktop_large") {
    return {
      portfolio: setIconPosition({ top: 0, left: 0 }),
      contacts: setIconPosition({ bottom: 0, left: 0 }),
      // special: setIconPosition({ top: 1, left: 0 }),
      awards: setIconPosition({ top: 0, left: 1 }),
      vacancies: setIconPosition({ bottom: 1, left: 0 }),
      gallery: setIconPosition({ top: 1, right: 0 }),
      paint: setIconPosition({ bottom: 1, right: 3 }),
      // audio: setIconPosition({ top: 0, right: 2 }),
      sapper: setIconPosition({ top: 0, right: 0 }),
      terminal: setIconPosition({ top: 0, right: 1 }),
      wallpaper: setIconPosition({ top: 2, right: 0 }),
      note: setIconPosition({ top: 1, left: 0 }),
      trash: setIconPosition({ bottom: 0, right: 0 })
    };
  } else {
    return {
      portfolio: setIconPosition({ top: 0, left: 0 }),
      contacts: setIconPosition({ bottom: 0, left: 0 }),
      // special: setIconPosition({ top: 1, left: 0 }),
      awards: setIconPosition({ top: 0, left: 1 }),
      vacancies: setIconPosition({ bottom: 1, left: 0 }),
      gallery: setIconPosition({ top: 1, right: 0 }),
      paint: setIconPosition({ bottom: 1, right: 3 }),
      // audio: setIconPosition({ top: 0, right: 2 }),
      sapper: setIconPosition({ top: 0, right: 0 }),
      terminal: setIconPosition({ top: 0, right: 1 }),
      wallpaper: setIconPosition({ top: 2, right: 0 }),
      note: setIconPosition({ top: 1, left: 0 }),
      trash: setIconPosition({ bottom: 0, right: 0 })
    };
  }
};

export default {
  name: "Home",
  components: {
    Homescreen,
    Taskbar,
    Shortcut,
    Folder,
    Contacts,
    Form,
    Terminal,
    Draw,
    Sapper,
    Screensaver,
    Gallery,
    Clients,
    Awards,
    Award,
    ProjectNew,
    Cookie,
    VacancyFolder,
    draggable
  },
  data() {
    return {
      theme: 1,
      themesCount: 8,
      darkmode: false,
      customWallpapers: [],
      customWallpaperUrl: null,
      folders: [],
      topShortcut: null,
      isDraggable: false,
      dragIdHolder: null,
      iconFoldersList: [
        {
          id: "trash",
          title: this.getTranslations("icons.trash")
        }
      ],
      clients: [],
      projects: [],
      vacancies: [],
      awards: [],
      icons: {
        homescreen: [
          {
            id: "contacts",
            title: this.getTranslations("icons.contacts"),
            type: "app",
            link: ""
          },
          {
            id: "portfolio",
            title: this.getTranslations("icons.portfolio"),
            type: "folder",
            link: ""
          },
          {
            id: "vacancies",
            title: this.getTranslations("icons.vacancy"),
            type: "folder",
            icon: VacancyIcon,
            link: ""
          },
          {
            id: "gallery",
            title: this.getTranslations("icons.gallery"),
            type: "folder",
            link: ""
          },
          {
            id: "terminal",
            title: this.getTranslations("icons.terminal"),
            type: "app",
            link: ""
          },
          {
            id: "wallpaper",
            title: this.getTranslations("icons.wallpaper"),
            type: "button",
            link: ""
          },
          {
            id: "paint",
            title: this.getTranslations("icons.paint"),
            type: "app",
            link: ""
          },
          {
            id: "sapper",
            title: this.getTranslations("icons.sapper"),
            type: "app",
            link: ""
          },
          {
            id: "awards",
            title: this.getTranslations("icons.awards"),
            type: "folder",
            link: ""
          },
          {
            id: "note",
            title: this.getTranslations("icons.note"),
            type: "link",
            link: ""
          },
          {
            id: "trash",
            title: this.getTranslations("icons.trash"),
            type: "folder",
            link: ""
          }
        ],
        projects: [],
        trash: []
      },
      iconsPositionsHolder: {},
      dragStartPosition: {
        x: 0,
        y: 0
      },
      moveAllowed: true,
      width: null,
      height: null,
      touched: false
    };
  },
  mounted() {
    this.setLocale();
    const folder = this.$route.params.folder;

    folder && this.folders.push(folder);

    this.setClients();
    this.setAwards();
    this.setPresentationLink();
    // this.setCustomWallpapers();
  },
  created() {
    this.$nextTick(() => {
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);
      window.addEventListener("touchstart", this.touchHandler, { once: true });
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    // setCustomWallpapers() {
    // this.customWallpapers = window.boot.wallpaper;
    // },
    setAwards() {
      axios.get("/awards").then(res => {
        let resAwards = res.data.data.payload;
        for (const award of resAwards) {
          this.awards.push(award);
        }
      });
    },
    setClients() {
      axios.get("/clients").then(res => {
        let resClients = res.data.data.payload;
        let clientsIcons = [];
        let clientsFolderIcons = [];
        for (const client of resClients) {
          this.clients.push(client);
          clientsFolderIcons.push({
            id: "client-" + String(client.id),
            title: {
              ru: client.name.ru,
              en: client.name.en
            },
            back: "portfolio",
            type: "client"
          });

          clientsIcons["client-" + client.id] = [];
          for (const project of client.projects.payload) {
            this.projects.push(project);
            clientsIcons["client-" + client.id].push({
              id: "project-" + String(project.id),
              title: {
                ru: project.title.ru,
                en: project.title.en
              },
              type: "project",
              icon: project.icon
            });
          }
        }
        this.icons = {
          ...this.icons,
          ...{ ...clientsIcons }
        };
        for (const folderIndex in clientsFolderIcons) {
          this.iconFoldersList.push(clientsFolderIcons[folderIndex]);
        }
      });
    },
    setPresentationLink() {
      axios.get("/presentations").then(res => {
        const isNote = element => {
          return element.id == "note";
        };
        const presentationIconIndex = this.icons.homescreen.findIndex(isNote);
        this.icons.homescreen[presentationIconIndex].link = res.data.data.file;
      });
    },
    touchHandler() {
      this.touched = true;
      this.isDraggable = false;
    },
    resizeHandler(event) {
      this.isDraggable = window.innerWidth > 767 && !this.touched;

      if (event) {
        const xdiff = this.width - window.innerWidth;
        const ydiff = this.height - window.innerHeight;

        for (const iconid in this.iconsPositionsHolder) {
          const position = this.iconsPositionsHolder[iconid];

          this.iconsPositionsHolder[iconid] = {
            x:
              position.x > window.innerWidth / 2
                ? position.x - xdiff
                : position.x,
            y:
              position.y > window.innerHeight / 2
                ? position.y - ydiff
                : position.y
          };
        }
      }

      this.width = window.innerWidth;
      this.height = window.innerHeight;

      this.updateDefaultIconsPositions();
    },
    shortcutClick(folderId) {
      if (folderId == "wallpaper") {
        this.updateWallpaper();
      }
    },
    toggleMode() {
      if (this.darkmode) {
        this.updateWallpaper(1);
      } else {
        this.updateWallpaper(2);
      }

      this.darkmode = !this.darkmode;
    },
    folderUp(folderId, iconType) {
      const folderIndex = this.folders.indexOf(folderId);
      const isOpen = folderIndex != -1;

      if (iconType && (iconType == "link" || iconType == "button")) {
        return;
      }

      if (isOpen) {
        this.folders.splice(folderIndex, 1);
      }
      this.folders.push(folderId);

      if (folderIndex < this.folders.length - 1) {
        this.$router.push(`${folderId}`).catch(err => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
      }
    },
    folderClose(folderId) {
      const folderIndex = this.folders.indexOf(folderId);

      this.folders.splice(folderIndex, 1);

      if (this.folders.length) {
        const folderId = this.folders[this.folders.length - 1];
        this.$router.push(`/${folderId}`).catch(err => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
      } else {
        this.$router.push(`/`).catch(err => {
          if (err.name != "NavigationDuplicated") {
            throw err;
          }
        });
      }
    },
    folderBack(closeId, openId) {
      this.folderClose(closeId);
      this.folderUp(openId);
    },
    shortcutUp(shortcutId) {
      this.topShortcut = shortcutId;
    },
    updateWallpaper(themeNum) {
      const { customWallpapers, themesCount, theme } = this;
      const limit = themesCount + customWallpapers.length;

      this.customWallpaperUrl = null;

      if (themeNum) {
        this.theme = themeNum;
      } else {
        if (theme >= themesCount) {
          if (theme < limit) {
            this.customWallpaperUrl = customWallpapers[limit - theme - 1];
            this.theme++;
          } else {
            this.theme = 1;
          }
        } else {
          this.theme++;
        }
      }
    },
    updateIconPosition(id, position) {
      this.iconsPositionsHolder = {
        ...this.iconsPositionsHolder,
        ...{ [id]: position }
      };
    },
    change(event) {
      if (event.removed) {
        this.dragIdHolder = event.removed.element.id;
      }
    },
    remove(event) {
      this.updateIconPosition(this.dragIdHolder, {
        x: event.originalEvent.clientX,
        y: event.originalEvent.clientY
      });
    },
    dragstart(event) {
      const targetRect = event.item
        .querySelector(".shortcut")
        .getBoundingClientRect();
      this.dragIdHolder = event.item.dataset.id;

      this.dragStartPosition = {
        x: event.originalEvent.clientX,
        y: event.originalEvent.clientY,
        diffX: event.originalEvent.clientX - targetRect.left,
        diffY: event.originalEvent.clientY - targetRect.top
      };
    },
    dragend(event) {
      let updated = {
        x: event.originalEvent.clientX - this.dragStartPosition.diffX,
        y: event.originalEvent.clientY - this.dragStartPosition.diffY
      };

      if (
        event.originalEvent.clientX == 0 &&
        event.originalEvent.clientY == 0
      ) {
        updated.x = 0;
        updated.y = 0;
      }

      if (this.moveAllowed) {
        this.updateIconPosition(this.dragIdHolder, {
          x: updated.x,
          y: updated.y
        });
      }

      this.moveAllowed = true;
    },
    checkMove(event) {
      let moveAllowed = true;
      const elementId = event.draggedContext.element.id;
      const toElement = event.originalEvent.target;

      if (elementId == "trash") {
        moveAllowed = false;
      }

      if (
        toElement.classList.contains(`folder__icons--${elementId}`) ||
        toElement.classList.contains(`shortcut__drop--${elementId}`)
      ) {
        moveAllowed = false;
      }

      this.moveAllowed = moveAllowed;

      return moveAllowed;
    },
    sendShortcutMove(id, value) {
      this.icons[id] += value;
    },
    getTranslation(arrayOfTrasnlations) {
      if (typeof arrayOfTrasnlations[this.$i18n.locale] === "string") {
        return arrayOfTrasnlations[this.$i18n.locale];
      }
      return arrayOfTrasnlations["ru"];
    },
    getTranslations(code) {
      return { en: this.$t(code, "en"), ru: this.$t(code, "ru") };
    },
    folderFooterClick(folderId) {
      if (folderId === "trash") {
        if (this.icons.trash && this.icons.trash.length) {
          this.$refs.trashAudio.play();
        }

        this.icons.trash = [];
      }
    },
    updateDefaultIconsPositions() {
      const { innerWidth, innerHeight } = window;

      if (innerWidth > 1220) {
        const preset =
          innerWidth < 1360 || innerHeight < 840
            ? "desktop_small"
            : "desktop_large";

        this.iconsPositionsHolder = getDefaultIconsPositions(preset);
      }
    },
    setLocale() {
      let domain = document.domain;
      if (domain.indexOf("ru") !== -1) {
        this.$i18n.locale = "ru";
        return;
      }
      if (domain.indexOf("com") !== -1) {
        this.$i18n.locale = "en";
        return;
      }
    }
  }
};
</script>

<style lang="scss">
@import "../styles/index.scss";
</style>
