<template>
  <div class="cookie" v-if="cookieVisible">
    <div class="cookie__header">
      Мы используем куки
      <button class="folder__close" @click="cookieAgreed()"></button>
    </div>
    <div class="cookie__body">
      <div class="cookie__body__text">
        Чтобы вам было удобнее играть в сапёра.
      </div>
      <div class="cookie__body__agree">
        <button @click="cookieAgreed()" class="gallery__btn gallery__nav-btn">
          [спасибо]
        </button>
      </div>
    </div>
    <div class="cookie__icon">
      <img :src="CookieIcon" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import CookieIcon from "@/assets/images/icons/Bomb3.png";

export default {
  name: "gallery",
  props: {},
  data() {
    return {
      CookieIcon,
      cookieVisible: false
    };
  },
  mounted() {
    this.checkCookie();
  },
  methods: {
    cookieAgreed() {
      axios.get("/user/cookieAgreed").then(({ data }) => {
        this.cookieVisible = data.data;
      });
    },
    checkCookie() {
      axios.get("/user/cookie").then(({ data }) => {
        this.cookieVisible = data.data;
      });
    }
  }
};
</script>
