<template>
  <draggable
    ghostClass="folder--ghost"
    :group="{ name: 'folder-' + folderId, pull: false, put: false }"
    :disabled="!isDraggable"
    handle=".folder__handle"
    :forceFallback="true"
    :fallbackTolerance="10"
    @start="dragstart"
    @end="dragend"
  >
    <div
      class="folder"
      :class="['folder--' + folderId]"
      :style="{
        top: position.y + 'px',
        left: position.x + 'px',
        zIndex: folders.indexOf(folderId) + 50
      }"
      @click="folderClick"
    >
      <div class="folder__handle" />
      <div
        class="vacancy__folder__header"
        :class="{
          vacancy__folder__header__none: hasActiveVacancy
        }"
        v-if="hasHeader"
      >
        вакансии
      </div>
      <button
        class="folder__back vacancy__folder__back"
        v-if="hasActiveVacancy"
        @click="clearActiveVacancy"
      >
        <img :src="BackIcon" />
      </button>
      <div class="vacancy__folder__title" v-if="hasActiveVacancy">
        {{ activeVacancy.title }}
      </div>
      <button
        class="folder__close"
        @click="$emit('layerClose', folderId)"
      ></button>
      <div class="vacancy__folder">
        <VacancyParents
          @setVacancy="changeActiveVacancy"
          :vacancyActive="hasActiveVacancy"
        />
        <Vacancy :vacancy="activeVacancy" :stage="activeStage" />
      </div>
      <div class="vacancy__folder__button" v-if="hasActiveVacancy">
        <button
          class="form__btn form__btn--fill"
          @click="plusStage"
          v-if="activeStage < 4"
        >
          {{ buttonText }}
        </button>
        <a
          :href="
            'mailto:hr@trinitymonsters.com?subject=Отклик на вакансию' +
              ' ' +
              activeVacancy.title
          "
          class="form__btn form__btn--fill"
          v-if="activeStage >= 4"
        >
          откликнуться
        </a>
      </div>
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import VacancyParents from "@/components/Vacancies/VacancyParents";
import Vacancy from "@/components/Vacancies/Vacancy";
import BackIcon from "@/assets/images/icons/Back.svg";

export default {
  name: "vacancyFolder",
  components: { VacancyParents, Vacancy, draggable },
  props: {
    header: String,
    folderId: String,
    folders: Array,
    isDraggable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasActiveVacancy() {
      return this.activeVacancy !== null;
    },
    hasHeader() {
      return this.header && this.header.length > 0;
    },
    buttonText() {
      if (this.activeStage < 2) {
        return "Расскажи поподробнее";
      } else {
        return "я такой человек";
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.position = this.getDefaultPosition();
    });
  },
  methods: {
    getDefaultPosition() {
      const {
        clientWidth: width,
        clientHeight: height
      } = this.$el.querySelector(".folder");
      const { folders } = this;

      return {
        y: window.innerHeight / 3 - height / 3,
        x: window.innerWidth / 2 - width / 2 - folders.length * 40
      };
    },
    dragstart(event) {
      this.dragStartPosition = {
        x: event.originalEvent.clientX,
        y: event.originalEvent.clientY
      };
    },
    dragend(event) {
      const { position, dragStartPosition } = this;

      this.$nextTick(() => {
        this.position = {
          x: position.x + event.originalEvent.clientX - dragStartPosition.x,
          y: position.y + event.originalEvent.clientY - dragStartPosition.y
        };
      });

      this.$emit("layerUp", this.folderId);
    },
    folderClick() {
      this.$emit("layerUp", this.folderId);
    },
    changeActiveVacancy(vacancy) {
      this.activeVacancy = vacancy;
      this.activeVacancyStage();
    },
    plusStage() {
      setTimeout(() => {
        this.$store.commit({
          type: "plusStage",
          vacancyId: this.activeVacancy.id
        });
        this.activeVacancyStage();
      }, 600);
      setTimeout(() => {
        this.$store.commit({
          type: "plusStage",
          vacancyId: this.activeVacancy.id
        });
        this.activeVacancyStage();
      }, 1200);
    },
    activeVacancyStage() {
      this.activeStage = this.$store.getters.getVacancyStageById(
        this.activeVacancy.id
      );
    },
    clearActiveVacancy() {
      this.activeVacancy = null;
    }
  },
  data() {
    return {
      BackIcon,
      activeStage: 0,
      vacancies: [],
      activeVacancy: null,
      position: {
        x: 0,
        y: 0
      },
      dragStartPosition: {
        x: 0,
        y: 0
      }
    };
  }
};
</script>
