<template>
  <div
    class="homescreen"
    :class="'homescreen--style-' + theme"
    :style="backgroundImage"
  >
    <template v-if="theme == 6">
      <div class="homescreen__tape homescreen__tape--gray">
        <div class="homescreen__tape-in">
          {{ $t("wallpaper.theme-6") }}
        </div>
      </div>
      <div class="homescreen__tape homescreen__tape--black">
        <div class="homescreen__tape-in">
          {{ $t("wallpaper.theme-6") }}
        </div>
      </div>
    </template>

    <div class="homescreen__in">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Homescreen",
  components: {},
  props: {
    theme: Number,
    icons: Array,
    customWallpaperUrl: String
  },
  computed: {
    backgroundImage() {
      const url = this.customWallpaperUrl;
      return url ? { "background-image": `url(${url})` } : null;
    }
  }
};
</script>
