<template>
  <div class="sapper">
    <div class="sapper__header">
      <div class="sapper__header-item">{{ round(bombsCount) }}</div>
      <div class="sapper__header-item sapper__header-item--center">
        <button
          class="sapper__reset"
          :class="[
            { 'sapper__reset--victory': victory },
            { 'sapper__reset--fail': fail }
          ]"
          @click="reset"
        ></button>
      </div>
      <div class="sapper__header-item">{{ gameTime }}</div>
    </div>
    <div class="sapper__grid" v-if="grid">
      <div class="sapper__grid-row" v-for="(itemY, y) in size.y" v-bind:key="y">
        <button
          class="sapper__cell"
          v-for="(itemX, x) in size.x"
          v-bind:key="x"
          :class="[
            {
              'sapper__cell--happy': grid[y][x] !== 'x' && isClicked(x, y)
            },
            { 'sapper__cell--bomb': grid[y][x] === 'x' && isClicked(x, y) },
            {
              'sapper__cell--bomb-hidden':
                grid[y][x] === 'x' && isBomb(x, y) && (fail || victory)
            }
          ]"
          :data-cell-text="getCellText(x, y)"
          @click="clickCell(x, y, $event)"
        ></button>
      </div>
    </div>

    <audio ref="bombAudio">
      <source src="/bomb.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>

<script>
export default {
  name: "Sapper",
  components: {},
  data() {
    return {
      size: {
        x: 8,
        y: 8
      },
      bombsCount: 10,

      gameTime: "000",
      timerInterval: null,
      startTime: null,
      blacklist: [],
      fail: false,
      grid: [],
      clicked: []
    };
  },
  created() {
    this.updateGridSize();
    this.reset();
    window.addEventListener("resize", this.updateGridSize);

    this.$nextTick(() => {
      this.$refs.bombAudio.volume = 0.6;
    });
  },
  computed: {
    victory() {
      return (
        this.size.x * this.size.y - this.bombsCount - this.clicked.length == 0
      );
    }
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    window.removeEventListener("resize", this.updateGridSize);
  },
  watch: {
    size() {
      this.reset();
    }
  },
  methods: {
    updateGridSize() {
      const isMobile = window.innerWidth < 768;
      const ratio = window.innerHeight / window.innerWidth;

      let mobileY = 6;

      if (ratio < 1.3) {
        mobileY = 3;
      } else if (ratio < 1.46) {
        mobileY = 4;
      } else if (ratio < 1.6) {
        mobileY = 5;
      }

      this.size = {
        x: isMobile ? 5 : 8,
        y: isMobile ? mobileY : 8
      };
    },
    reset() {
      this.gameTime = "000";
      this.blacklist = [];
      this.fail = false;
      this.grid = [];
      this.clicked = [];
      this.timerInterval && clearInterval(this.timerInterval);

      this.createBlacklist();
      this.createGrid();
    },
    start() {
      this.startTime = new Date();
      this.timerInterval = setInterval(this.updateTime, 500);
    },
    createBlacklist() {
      const { size, bombsCount } = this;
      let blacklist = [];

      while (blacklist.length < bombsCount) {
        const bomb = `${Math.floor(Math.random() * size.x)}, ${Math.floor(
          Math.random() * size.y
        )}`;

        if (blacklist.indexOf(bomb) === -1) {
          blacklist.push(bomb);
        }
      }

      this.blacklist = blacklist;
    },
    createGrid() {
      const { size, checkCell } = this;
      const grid = [];

      for (let y = 0; y < size.y; y++) {
        grid.push([]);

        for (let x = 0; x < size.x; x++) {
          grid[y][x] = checkCell(x, y);
        }
      }

      this.grid = grid;

      // console.log(grid);
    },
    checkCell(x, y) {
      const isBomb = this.isBomb(x, y);
      const checklist = [
        [x - 1, y - 1],
        [x - 1, y],
        [x - 1, y + 1],
        [x, y - 1],
        [x, y + 1],
        [x + 1, y - 1],
        [x + 1, y],
        [x + 1, y + 1]
      ];
      let count = 0;

      if (isBomb) {
        count = "x";
      } else {
        for (const cell of checklist) {
          const [cellX, cellY] = cell;
          const isBomb = this.isBomb(cellX, cellY);

          if (isBomb) {
            count++;
          }
        }
      }

      return `${count}`;
    },
    clickCell(x, y) {
      if (!this.clicked.length) {
        this.start();
      }
      if (!this.isClicked(x, y) && !this.fail) {
        this.clicked.push(`${x}, ${y}`);

        if (this.grid[y][x] === "0") {
          this.checkForEmptySiblings(x, y);
        }
        if (this.grid[y][x] === "x") {
          this.fail = true;
          this.$refs.bombAudio.play();
        }
      }
    },
    isClicked(x, y) {
      return this.clicked.indexOf(`${x}, ${y}`) !== -1;
    },
    isBomb(x, y) {
      return this.blacklist.indexOf(`${x}, ${y}`) !== -1;
    },
    getCellText(x, y) {
      if (
        this.isClicked(x, y) &&
        this.grid[y][x] !== "x" &&
        this.grid[y][x] !== "0"
      ) {
        return this.grid[y][x];
      } else {
        return "";
      }
    },
    checkForEmptySiblings(x, y) {
      const checklist = [
        [x - 1, y - 1],
        [x - 1, y],
        [x - 1, y + 1],
        [x, y - 1],
        [x, y + 1],
        [x + 1, y - 1],
        [x + 1, y],
        [x + 1, y + 1]
      ];
      const size = this.size;

      for (const cell of checklist) {
        const [cellX, cellY] = cell;

        if (cellX >= 0 && cellX < size.x && cellY >= 0 && cellY < size.y) {
          this.clickCell(cellX, cellY);
        }
      }
    },
    updateTime() {
      if (this.fail || this.victory) {
        clearInterval(this.timerInterval);
      } else {
        const lastTime = Math.floor((new Date() - this.startTime) / 1000);

        if (lastTime >= 999) {
          this.fail = true;
          this.gameTime = "999";
        } else {
          this.gameTime = this.round(lastTime);
        }
      }
    },
    round(value) {
      return value < 100 ? (value < 10 ? `00${value}` : `0${value}`) : value;
    }
  }
};
</script>
