<template>
  <div class="terminal">
    <div
      class="terminal__body"
      ref="letterstarget"
      v-html="about"
      @click.stop="skipLine"
    ></div>
    <div class="terminal__footer">
      <p>
        design by <a href="https://eshgruppa.com/" target="_blank">eshgruppa</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Terminal",
  components: {},
  data() {
    return {
      lines: [],
      drawTimer: null,
      skipLineStatus: false
    };
  },
  created() {
    this.init();
  },
  computed: {
    about() {
      return this.$t("about.message");
    }
  },
  watch: {
    about() {
      this.init();
    }
  },
  methods: {
    init() {
      clearTimeout(this.drawTimer);

      this.$nextTick(() => {
        this.checkForChildren(this.$refs.letterstarget);

        this.lines = this.$el.querySelectorAll("[data-text]");
        this.drawLine(0);

        this.checkLinks();
      });
    },
    drawLine(lineIndex) {
      const line = this.lines[lineIndex];

      if (line) {
        const text = line.getAttribute("data-text");

        line.innerHTML = "";

        this.drawLetter(lineIndex, line, text);
      }
    },
    drawLetter(lineIndex, line, text) {
      const isCommand = line.classList.contains("terminal__command");
      const isBreak = line.classList.contains("terminal__break");
      let letterDelay = isCommand ? 100 : isBreak ? 5 : 20;
      let lineDelay = 100;
      const index = line.innerHTML.length;
      if (this.skipLineStatus == true) {
        lineDelay = 0;
        letterDelay = 0;
      }
      if (index < text.length) {
        this.drawTimer = setTimeout(() => {
          line.innerHTML += text[index];
          this.drawLetter(lineIndex, line, text);
        }, letterDelay);
      } else {
        this.drawTimer = setTimeout(() => {
          this.drawLine(lineIndex + 1);
        }, lineDelay);
      }
    },
    checkForChildren(element) {
      const childNodes = element.childNodes;
      const children = element.children;

      if (childNodes.length > 1) {
        for (const node of childNodes) {
          if (node.nodeType === 3) {
            //textnode

            element.replaceChild(
              this.addWrapElement(node, node.nodeValue),
              node
            );
          } else if (node.nodeType === 1) {
            //elementnode

            if (node.tagName.toLowerCase() == "hr") {
              element.replaceChild(this.getLineElement(), node);
            } else {
              this.checkForChildren(node);
            }
          }
        }
      } else {
        if (childNodes.length === 1 && children.length === 0) {
          element.appendChild(this.addWrapElement(element));
        }
      }
    },
    addWrapElement(element, customText) {
      let text = customText || element.innerText;
      const node = document.createElement("SPAN");

      element.innerText = "";
      text = text.replace(/\u00a0/g, " ");
      node.setAttribute("data-text", text);

      return node;
    },
    getLineElement() {
      const node = document.createElement("SPAN");

      node.classList.add("terminal__break");
      node.setAttribute(
        "data-text",
        "----------------------------------------------------------------"
      );

      return node;
    },
    checkLinks() {
      const links = this.$refs.letterstarget.querySelectorAll("a[href]");
      for (const link of links) {
        link.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.$emit("layerUp", "order");
        });
      }
    },
    skipLine() {
      this.skipLineStatus = true;
    }
  }
};
</script>
