<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 80.7 60"
    style="enable-background:new 0 0 80.7 60;"
    xml:space="preserve"
    :style="cssVars"
  >
    <g></g>
    <g>
      <g>
        <g>
          <circle class="st0" cx="41.2" cy="28.7" r="17.8" />
        </g>
        <g>
          <path
            class="st1"
            d="M35.3,45.4c6.8-2.5,11.6-9.1,11.6-16.7c0-7.7-4.8-14.2-11.7-16.7l0,0c1.9-0.7,4-1.1,6.2-1.1
				c9.8,0,17.8,8,17.8,17.8s-8,17.8-17.8,17.8C39.3,46.5,37.2,46.1,35.3,45.4L35.3,45.4z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M35.9,47.8l-1.7-4.7c6-2.2,10-8,10-14.4c0-6.4-4-12.2-10.1-14.4l1.7-4.7c8,2.9,13.3,10.6,13.3,19.1
				C49.2,37.2,43.8,44.8,35.9,47.8z"
          />
        </g>
        <g>
          <path
            class="st0"
            d="M41.2,49C30,49,20.9,39.9,20.9,28.7S30,8.3,41.2,8.3s20.3,9.1,20.3,20.3S52.4,49,41.2,49z M41.2,13.3
				c-8.5,0-15.3,6.9-15.3,15.3S32.7,44,41.2,44s15.3-6.9,15.3-15.3S49.6,13.3,41.2,13.3z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Themeicon",
  props: {
    darkmode: Boolean
  },
  computed: {
    cssVars() {
      return {
        "--color1": this.darkmode ? "#000000" : "#ffffff",
        "--color2": this.darkmode ? "#ffffff" : "#000000",
        "--color-hover": this.darkmode ? "#b1b1b1" : "#c5ff01"
      };
    }
  }
};
</script>

<style type="text/css" scoped>
.st0 {
  fill: var(--color1);
}
.st1 {
  fill: var(--color2);
}
svg:hover .st0 {
  fill: var(--color-hover);
}
</style>
