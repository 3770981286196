<template>
  <div class="project__new">
    <div
      class="project__new__description"
      ref="linktarget"
      v-html="description"
    ></div>
    <div class="project__new__main__image">
      <img :src="project.main_image" />
    </div>
    <div class="project__new__comment" v-if="project.comment">
      <div class="project__new__comment_author__icon">
        <img :src="project.comment.comment_icon" />
      </div>
      <div class="project__new__comment__content">
        <div class="project__new__comment__author">
          {{ comment_author }}
        </div>
        <div class="project__new__comment__text">
          {{ comment }}
        </div>
      </div>
    </div>
    <div class="project__new__images">
      <template v-for="(image, index) in project.small_images">
        <div
          class="project__new__images__small"
          :key="index"
          :class="{
            project__new__images__small__padding__right: index % 2 === 0
          }"
        >
          <img :src="image" width="304px" height="228px" />
        </div>
      </template>
      <template v-for="(image, key) in project.big_images">
        <div class="project__new__images__big" :key="key">
          <img :src="image" />
        </div>
      </template>
    </div>
    <div class="project__new__employees" v-if="project.employees.length !== 0">
      <div class="project__new__employees__title">
        Монстры проекта
      </div>
      <div class="project__new__employees__body">
        <template v-for="(_, grade) in project.employees">
          <div class="project__new__employee" :key="grade">
            <div class="project__new__employee__grade">
              {{ grade }}
            </div>
            <div
              class="project__new__employee__name"
              v-for="(employee, index) in project.employees[grade]"
              :key="index"
            >
              {{ employee.name + " " + employee.surname }}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import path from "@/assets/images/icons/Path.svg";
// import tree from "@/assets/images/icons/Tree.svg";
// import axios from 'axios';
export default {
  name: "ProjectNew",
  props: {
    project: Object
  },
  computed: {
    description() {
      return this.getTranslation(this.project.description);
    },
    comment() {
      return this.getTranslation(this.project.comment.comment);
    },
    comment_author() {
      return this.getTranslation(this.project.comment.comment_author);
    }
  },
  methods: {
    getTranslation(arrayOfTrasnlations) {
      if(typeof arrayOfTrasnlations[this.$i18n.locale] === "string") {
        return arrayOfTrasnlations[this.$i18n.locale];
      }
      return arrayOfTrasnlations["ru"];
    },
  }
};
</script>
<style>
.project__new__description a {
  color: #c5ff01;
  text-decoration: none;
}
.project__new__description a:hover {
  color: #9cc419e0;
}
</style>
