<template>
  <div class="gallery">
    <div class="gallery__header">
      Видели иконку пэйнта на нашем сайте? Каждый день посетители создают там
      настоящие шедевры исскуства. С 2019 года у нас накопилось
      {{ counts.alltime }} работ, и мы заботливо ведем для вас галерею!
    </div>

    <div class="gallery__nav">
      <button
        v-for="scope in scopes"
        v-bind:key="scope.id"
        @click="changeActiveScope(scope.id)"
        class="gallery__btn gallery__nav-btn"
        :class="{ 'gallery__btn--active': activeScope == scope.id }"
      >
        [{{ scope.text }}, {{ counts[scope.id] }}]
      </button>
    </div>

    <div class="gallery__content">
      <div class="gallery__item" v-for="(paint, $index) in paints" v-bind:key="$index">
        <img class="gallery__item-img" :src="paint.url" :alt="paint.name" />
        <div class="gallery__item-name">{{ paint.name }}</div>

        <button
          class="gallery__btn gallery__item-like"
          :class="{ 'gallery__btn--active': paint.voted }"
          @click="vote(paint.id)"
        >
          [laykov {{ paint.votes }}]
        </button>
      </div>
      <infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: "gallery",
  props: {},
  components: {
    InfiniteLoading
  },
  data() {
    return {
      scopes: [
        {
          id: "week",
          text: "za nedelyu"
        },
        {
          id: "best",
          text: "luchshie"
        },
        {
          id: "allTime",
          text: "za vsyo vremya"
        }
      ],
      paints: [],
      counts: {},
      activeScope: "allTime",
      page: 1,
      infiniteId: +new Date(),
    };
  },
  watch: {
    activeScope() {
      this.infiniteHandler();
    }
  },
  methods: {
    infiniteHandler($state) {
      axios.get("/images", {
        params: {
          scope: this.activeScope,
          page: this.page
        },
      }).then(({ data }) => {
        if (data.data.payload.length) {
          this.page += 1;
          this.paints.push(...data.data.payload);
          this.counts = {
            week: data.data.week,
            best: data.data.best,
            allTime: data.data.allTime
          };
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    vote(id) {
      var paint = this.paints.find(item => item.id === id);
      if(!paint.voted) {
        paint.votes = paint.votes + 1;
        paint.voted = true;
        axios.get(`/images/vote/${id}`);
      }
    },
    changeActiveScope(scopeId) {
      this.activeScope = scopeId;
      this.page = 1;
      this.paints = [];
      this.infiniteId += 1;
    }
  }
};
</script>
