<template>
  <div class="award">
    <img :src="image" />
  </div>
</template>

<script>
export default {
  name: "award",
  props: {
    image: String
  }
};
</script>
<style>
img {
  max-width: 100%;
  max-height: 100%;
}
</style>
