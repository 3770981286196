<template>
  <div class="awards">
    <template v-for="year in years">
      <div class="awards__folder__year" :key="year">
        <div class="awards__folder__year__field">
          {{ year }}
        </div>
        <template v-for="award in awards">
          <a
            :href="award.link"
            :key="award.id"
            target="_blank"
            v-if="award.type === 'link'"
          >
            <div class="awards__folder" v-if="award.years.indexOf(year) != -1">
              <div class="awards__folder__left">
                <img :src="LinkIcon" width="46px" />
                <div class="awards__folder__text">
                  <span class="awards__folder__text__span">
                    {{ getTranslation(award.title) }}
                  </span>
                </div>
              </div>
              <div class="awards__folder__right"></div>
            </div>
          </a>
          <a
            :key="award.id"
            v-if="award.type === 'img'"
            @click.stop="showFolder('award-' + award.id)"
          >
            <div class="awards__folder" v-if="award.years.indexOf(year) != -1">
              <div class="awards__folder__left">
                <img :src="ImageIcon" />
                <div class="awards__folder__text">
                  <span class="awards__folder__text__span">
                    {{ getTranslation(award.title) }}
                  </span>
                </div>
              </div>
              <div class="awards__folder__right"></div>
            </div>
          </a>
        </template>
        <h4 class="dotted-line"></h4>
      </div>
    </template>
  </div>
</template>

<script>
import ImageIcon from "@/assets/images/icons/ImageIcon.svg";
import LinkIcon from "@/assets/images/icons/link.svg";
import axios from "axios";

export default {
  name: "awards",
  data() {
    return {
      awards: [],
      years: [],
      LinkIcon,
      ImageIcon
    };
  },
  mounted() {
    this.setYears();
    this.setAwards();
  },
  methods: {
    setAwards() {
      axios.get("/awards").then(res => {
        this.awards = res.data.data.payload;
      });
    },
    setYears() {
      axios.get("/years/awards").then(res => {
        this.years = res.data.data;
      });
    },
    showFolder(folderId) {
      this.$emit("layerBack", "awards", folderId);
    },
    getTranslation(arrayOfTrasnlations) {
      if(typeof arrayOfTrasnlations[this.$i18n.locale] === "string") {
        return arrayOfTrasnlations[this.$i18n.locale];
      }
      return arrayOfTrasnlations["ru"];
    }
  }
};
</script>
<style>
.awards {
  font-family: IBM Plex Mono;
  font-size: 20px;
  max-width: 670px;
  word-break: break-word;
  padding-right: 38px;
  line-height: 26px;
}
.awards a {
  text-decoration: none;
  color: #ececec;
}
.awards a:hover {
  color: #c5ff01;
}
.awards__folder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}
.awards__folder__left {
  width: 90%;
}
.awards__folder__right {
  padding-top: 8px;
  width: 1%;
  text-align: right;
}
.awards__folder__text {
  width: 100%;
  word-break: break-word;
  position: relative;
  left: 64px;
  bottom: 36px;
}
.awards__folder__text__span {
  position: relative;
}
.awards__folder__year {
  padding-bottom: 24px;
}
.dotted-line {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  margin-top: 0;
}
.dotted-line:after {
  content: "..........................................................................................................";
  letter-spacing: 6px;
  color: #ececec;
  display: inline-block;
  vertical-align: 3px;
  padding-left: 10px;
}
.awards__folder__year__field {
  padding-bottom: 20px;
}
</style>
