var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shortcut",class:[
    'shortcut--' + _vm.shortcutId,
    { 'shortcut--top': _vm.topShortcut == _vm.shortcutId }
  ],style:({ top: _vm.position.y + 'px', left: _vm.position.x + 'px' }),on:{"click":function($event){$event.stopPropagation();return _vm.click.apply(null, arguments)}}},[(_vm.link && _vm.link.length)?_c('a',{staticClass:"shortcut__handle",attrs:{"href":_vm.link,"target":"_blank"}}):_c('div',{staticClass:"shortcut__handle"}),_c('div',{staticClass:"shortcut__icon",class:[
      'shortcut__icon--' + _vm.shortcutId,
      _vm.isListEmpty ? `shortcut__icon--${_vm.shortcutId}-empty` : null,
      _vm.hasCustomIcon ? 'shortcut__icon--custom' : null,
      _vm.hasCustomIcon && _vm.customImageLoaded
        ? 'shortcut__icon--custom-loaded'
        : null
    ]},[(_vm.hasCustomIcon)?_c('img',{staticClass:"shortcut__icon-img",class:{
        'shortcut__icon-img--hover-wait': _vm.hasCustomIcon && _vm.iconHover
      },attrs:{"src":_vm.icon,"alt":""},on:{"load":function($event){_vm.customImageLoaded = true}}}):_vm._e(),(_vm.hasCustomIcon && _vm.iconHover)?_c('img',{staticClass:"shortcut__icon-img shortcut__icon-img--hover",attrs:{"src":_vm.iconHover,"alt":""},on:{"load":function($event){_vm.customImageLoaded = true}}}):_vm._e(),(_vm.isTrash)?_c('Trashicon',{attrs:{"empty":_vm.isListEmpty,"cleaned":_vm.isListEmpty && _vm.dirty}}):_vm._e()],1),_c('div',{staticClass:"shortcut__label"},[_vm._v(_vm._s(_vm.text))]),(_vm.type == 'folder')?[_c('draggable',_vm._b({},'draggable',_vm.dragOptions,false),[(_vm.shortcutId === 'trash')?_c('transition-group',{class:_vm.dropClass,attrs:{"type":"animation","name":"shortcut__drop-in-"}},_vm._l((_vm.iconsList),function(icon){return _c('div',{key:icon.id,staticClass:"shortcut__drop-in"},[_c('Shortcut',{attrs:{"text":_vm.getTranslation(icon.title),"type":icon.type,"shortcutId":icon.id,"icon":icon.icon,"list":[]}})],1)}),0):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }