<template>
  <div class="screensaver" ref="screensaver" v-if="showScreensaver">
    <div
      class="screensaver__item screensaver__item--black screensaver__black"
      v-if="current.name == 'black'"
    >
      <div class="screensaver__black-images">
        <img src="@/assets/images/screensaver-1.svg" alt="" />
        <img src="@/assets/images/screensaver-1.svg" alt="" />
      </div>
      <div class="screensaver__black-line"></div>
      <div class="screensaver__black-text">
        Проектирование Прототипирование Продуктовая аналитика Frontend Верстка
        Backend CMS CRM CUSTDEV Code review DevOps Поддержка Интеграция TDD
        Дизайн интерфейсов Обучение ML Проектирование Прототипирование
        Продуктовая аналитика Frontend Верстка Backend CMS CRM CUSTDEV Code
        review DevOps Поддержка Интеграция TDD Дизайн интерфейсов Обучение ML
      </div>
    </div>
    <div
      class="screensaver__item screensaver__item--run"
      v-if="current.name == 'run'"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Screensaver",
  components: {},
  data() {
    return {
      screensavers: [
        { name: "black", duration: 0 }
        // { name: "run", duration: 6000 }
      ],
      showScreensaver: false,
      delayTimer: null,
      durationTimer: null,
      fadeoutTimer: null,
      delay: 20000,
      touchDevice: false,
      current: null,
      fadeoutDuration: 300
    };
  },
  created() {
    this.$nextTick(() => {
      this.resizeHandler();
      window.addEventListener("resize", this.resizeHandler);
      window.addEventListener("touchstart", this.touchHandler, { once: true });
    });
  },
  watch: {
    showScreensaver(value) {
      value && this.init();
    }
  },
  methods: {
    init() {
      const screensavers = this.screensavers;

      this.current =
        screensavers[Math.floor(Math.random() * screensavers.length)];

      if (this.current.duration) {
        this.fadeoutTimer = setTimeout(
          this.fadeout,
          this.current.duration - this.fadeoutDuration
        );

        this.durationTimer = setTimeout(
          this.updateDelayTimer,
          this.current.duration
        );
      }
    },
    fadeout() {
      const screensaver = this.$refs.screensaver;
      screensaver.classList.add("screensaver--out");
    },
    touchHandler() {
      this.touchDevice = true;
      this.resizeHandler();
    },
    resizeHandler() {
      const updateDelayTimer = this.updateDelayTimer;
      const isDevice = window.innerWidth < 960 || this.touchDevice;

      if (isDevice) {
        this.resetTimer();
        window.removeEventListener("mousemove", updateDelayTimer);
        window.removeEventListener("click", updateDelayTimer);
      } else {
        updateDelayTimer();
        window.addEventListener("mousemove", updateDelayTimer);
        window.addEventListener("click", updateDelayTimer);
      }
    },
    updateDelayTimer() {
      this.resetTimer();

      this.delayTimer = setTimeout(() => {
        this.showScreensaver = true;
      }, this.delay);
    },
    resetTimer() {
      clearTimeout(this.delayTimer);
      clearTimeout(this.fadeoutTimer);
      clearTimeout(this.durationTimer);
      this.showScreensaver = false;
    }
  }
};
</script>
