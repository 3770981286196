var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sapper"},[_c('div',{staticClass:"sapper__header"},[_c('div',{staticClass:"sapper__header-item"},[_vm._v(_vm._s(_vm.round(_vm.bombsCount)))]),_c('div',{staticClass:"sapper__header-item sapper__header-item--center"},[_c('button',{staticClass:"sapper__reset",class:[
          { 'sapper__reset--victory': _vm.victory },
          { 'sapper__reset--fail': _vm.fail }
        ],on:{"click":_vm.reset}})]),_c('div',{staticClass:"sapper__header-item"},[_vm._v(_vm._s(_vm.gameTime))])]),(_vm.grid)?_c('div',{staticClass:"sapper__grid"},_vm._l((_vm.size.y),function(itemY,y){return _c('div',{key:y,staticClass:"sapper__grid-row"},_vm._l((_vm.size.x),function(itemX,x){return _c('button',{key:x,staticClass:"sapper__cell",class:[
          {
            'sapper__cell--happy': _vm.grid[y][x] !== 'x' && _vm.isClicked(x, y)
          },
          { 'sapper__cell--bomb': _vm.grid[y][x] === 'x' && _vm.isClicked(x, y) },
          {
            'sapper__cell--bomb-hidden':
              _vm.grid[y][x] === 'x' && _vm.isBomb(x, y) && (_vm.fail || _vm.victory)
          }
        ],attrs:{"data-cell-text":_vm.getCellText(x, y)},on:{"click":function($event){return _vm.clickCell(x, y, $event)}}})}),0)}),0):_vm._e(),_c('audio',{ref:"bombAudio"},[_c('source',{attrs:{"src":"/bomb.mp3","type":"audio/mpeg"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }