<template>
  <div class="contacts">
    <div class="contacts__columns">
      <div class="contacts__column">
        <div class="contacts__column__element contacts__link contacts__email">
          <a :href="'mailto:' + email">{{ email }}</a>
        </div>
        <div class="contacts__column__element contacts__row">
          <div class="contacts__row__element contacts__link contacts__social">
            <a href="https://www.instagram.com/trinitymonsters/" target="_blank"
              >instagram</a
            >
          </div>
          <div class="contacts__row__element contacts__link contacts__social">
            <a href="https://www.behance.net/trinity_digital" target="_blank"
              >behance</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contacts",
  computed: {
    email() {
      return this.$t("contact.email");
    }
  }
};
</script>
