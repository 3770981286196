<template>
  <div class="clients">
    <div class="clients__link">
      <a @click="randomProject"> [{{ $t("portfolio.random_project") }}] </a>
    </div>
    <template v-for="year in years">
      <div class="clients__folder__year" :key="year">
        <div class="clients__folder__year__field">
          {{ year }}
        </div>
        <template v-for="(client, index) in clients">
          <div
            class="clients__folder__with__subfolder"
            :key="client.id"
            v-if="
              client.projects.payload.length > 1 &&
                checkClientProjectYears(index, year)
            "
          >
            <div
              class="clients__folder"
              @click="showFolder('client-' + client.id)"
            >
              <div class="clients__folder__left">
                <img :src="FolderIcon" class="clients__folder__icon" />
                <div class="clients__folder__text">
                  {{ getTranslation(client.name) }}
                </div>
              </div>
              <div class="clients__folder__right">
                {{ $tc("project-count", client.count) }}
              </div>
            </div>
          </div>
          <div
            class="clients__one__project"
            :key="client.id"
            v-if="client.projects.payload.length === 1"
          >
            <template v-for="project in client.projects.payload">
              <div
                class="clients__folder"
                :key="project.id"
                v-if="project.year.indexOf(year) != -1"
                @click="showFolder('project-' + project.id)"
              >
                <div class="clients__folder__left clients__folder__fileIcon">
                  <img :src="FileIcon" class="clients__folder__icon clients__folder_icon_file" />
                  <div class="clients__folder__text">
                    {{ getTranslation(client.name) }}
                  </div>
                </div>
                <div class="clients__folder__right">
                  {{ getTranslation(project.type.type) }}
                </div>
              </div>
            </template>
          </div>
        </template>
        <h4 class="dotted-line"></h4>
      </div>
    </template>
  </div>
</template>

<script>
import FolderIcon from "@/assets/images/icons/Path.svg";
import FileIcon from "@/assets/images/icons/file.svg";
import axios from "axios";

export default {
  name: "clients",
  data() {
    return {
      clients: [],
      years: [],
      FolderIcon,
      FileIcon
    };
  },
  mounted() {
    this.setYears();
    this.setClients();
  },
  methods: {
    setClients() {
      axios.get("/clients").then(res => {
        let resClients = res.data.data.payload;
        for (const client of resClients) {
          this.clients.push(client);
        }
      });
    },
    setYears() {
      axios.get("/years/projects").then(res => {
        let resYears = res.data.data;
        this.years = resYears;
      });
    },
    randomProject() {
      axios.get("/random/projects").then(res => {
        this.showFolder("project-" + res.data);
      });
    },
    showFolder(folderId) {
      this.$emit("layerBack", "portfolio", folderId);
    },
    checkClientProjectYears(clientIndex, year) {
      let projects = this.clients[clientIndex].projects.payload;
      for (let i = 0; i < projects.length; i++) {
        let years = JSON.parse(projects[i].year);
        for (let z = 0; z < years.length; z++) {
          if (years[z] == year) {
            return true;
          }
        }
      }
      return false;
    },
    getTranslation(arrayOfTrasnlations) {
      if(typeof arrayOfTrasnlations[this.$i18n.locale] === "string") {
        return arrayOfTrasnlations[this.$i18n.locale];
      }
      return arrayOfTrasnlations["ru"];
    }
  }
};
</script>
