<template>
  <div class="draw">
    <div class="draw__header">
      <label
        v-for="color in colorList"
        v-bind:key="color.value"
        class="draw__color"
        :class="{ 'draw__color--active': isActive(color.label) }"
        :style="{
          backgroundColor: color.value,
          borderColor: isActive(color.label) ? color.border : 'transparent'
        }"
      >
        <input
          class="draw__color-input"
          type="radio"
          name="draw-color"
          v-model="selectedColor"
          :value="color.label"
          @change="selectColor(color.value)"
        />
      </label>
    </div>
    <div class="draw__canvas-wrap">
      <canvas
        class="draw__canvas"
        id="draw-canvas"
        @mousedown.prevent="startDrawing"
        @mousemove="drawLine"
        @mouseup="stopDrawing"
        @mouseout="stopDrawing"
        @touchstart.prevent="startDrawing"
        @touchmove="drawLine"
        @touchend="stopDrawing"
        v-show="!success"
      ></canvas>

      <div
        class="draw__canvas-success"
        v-if="success"
        v-html="$t('draw.success')"
      ></div>
    </div>
    <div class="draw__footer">
      <a
        class="draw__btn draw__btn--save"
        download="trinity.png"
        :href="image"
        v-if="!success"
        >{{ $t("draw.save") }}</a
      >
      <button
        class="draw__btn draw__btn--fill draw__btn--send"
        @click="submit"
        :disabled="disabled"
        v-if="!success"
      >
        {{ $t("draw.send") }}
      </button>
      <button
        class="draw__btn draw__btn--success"
        @click="reset"
        v-if="success"
      >
        ок
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Draw",
  components: {},
  data() {
    return {
      selectedColor: "white",
      colorList: [
        { label: "white", value: "#ffffff", border: "transparent" },
        { label: "gray", value: "#b1b1b1", border: "#ececec" },
        { label: "orange", value: "#f5963d", border: "#ececec" },
        { label: "yellow", value: "#ffe52c", border: "#ececec" },
        { label: "pink", value: "#f571a1", border: "#ececec" },
        { label: "green", value: "#00fb51", border: "#ececec" },
        { label: "lime", value: "#c5ff01", border: "#ececec" }
      ],
      isMouseDown: false,
      x: 0,
      y: 0,
      canvas: null,
      image: null,
      disabled: true,
      success: false
    };
  },
  created() {
    this.$nextTick(() => {
      const canvas = this.$el.querySelector("canvas");
      const canvasWrap = this.$el.querySelector(".draw__canvas-wrap");

      canvas.width = canvasWrap.clientWidth;
      canvas.height = canvasWrap.clientHeight;

      this.context = canvas.getContext("2d");
      this.context.lineCap = "round";
      this.context.lineWidth = 5;
      this.context.strokeStyle = this.selectedColor;

      this.canvas = canvas;

      this.fillBackground();
    });
  },
  methods: {
    isActive(label) {
      return label === this.selectedColor;
    },
    stopDrawing() {
      this.isMouseDown = false;
      this.image = this.canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    },
    startDrawing(event) {
      const isTouch = /touch/gi.test(event.type);
      this.isMouseDown = true;

      if (isTouch) {
        const canvasRect = event.target.getBoundingClientRect();

        this.x = event.touches[0].clientX - canvasRect.left;
        this.y = event.touches[0].clientY - canvasRect.top;
      } else {
        this.x = event.offsetX;
        this.y = event.offsetY;
      }
    },
    drawLine(event) {
      const { context, isMouseDown, x, y } = this;

      if (isMouseDown) {
        const isTouch = /touch/gi.test(event.type);
        let newX = 0;
        let newY = 0;

        if (isTouch) {
          const canvasRect = event.target.getBoundingClientRect();

          newX = event.touches[0].clientX - canvasRect.left;
          newY = event.touches[0].clientY - canvasRect.top;
        } else {
          newX = event.offsetX;
          newY = event.offsetY;
        }

        context.beginPath();
        context.moveTo(x, y);
        context.lineTo(newX, newY);
        context.stroke();

        this.x = newX;
        this.y = newY;

        this.disabled = false;
      }
    },
    selectColor(color) {
      this.context.strokeStyle = color;
    },
    submit() {
      if (this.disabled) {
        return;
      }

      this.canvas.toBlob(async blob => {
        const data = new FormData();
        data.append("file", blob, "masterpiece.png");

        try {
          await this.axios.post("upload/image", data, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          this.success = true;
        } catch (e) {
          // console.log("error");
          this.reset();
        }
      });
    },
    reset() {
      this.success = false;
      this.disabled = true;

      this.fillBackground();
    },
    fillBackground() {
      this.context.fillStyle = "#000000";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }
};
</script>
