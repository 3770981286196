var render = function render(){var _vm=this,_c=_vm._self._c;return _c('draggable',{attrs:{"ghostClass":"folder--ghost","group":{ name: 'folder-' + _vm.folderId, pull: false, put: false },"disabled":!_vm.isDraggable,"handle":".folder__handle","forceFallback":true,"fallbackTolerance":10},on:{"start":_vm.dragstart,"end":_vm.dragend}},[_c('div',{staticClass:"folder",class:['folder--' + _vm.folderId],style:({
      top: _vm.position.y + 'px',
      left: _vm.position.x + 'px',
      zIndex: _vm.folders.indexOf(_vm.folderId) + 50
    }),on:{"click":_vm.folderClick}},[_c('div',{staticClass:"folder__handle"}),(_vm.hasHeader)?_c('div',{staticClass:"vacancy__folder__header",class:{
        vacancy__folder__header__none: _vm.hasActiveVacancy
      }},[_vm._v(" вакансии ")]):_vm._e(),(_vm.hasActiveVacancy)?_c('button',{staticClass:"folder__back vacancy__folder__back",on:{"click":_vm.clearActiveVacancy}},[_c('img',{attrs:{"src":_vm.BackIcon}})]):_vm._e(),(_vm.hasActiveVacancy)?_c('div',{staticClass:"vacancy__folder__title"},[_vm._v(" "+_vm._s(_vm.activeVacancy.title)+" ")]):_vm._e(),_c('button',{staticClass:"folder__close",on:{"click":function($event){return _vm.$emit('layerClose', _vm.folderId)}}}),_c('div',{staticClass:"vacancy__folder"},[_c('VacancyParents',{attrs:{"vacancyActive":_vm.hasActiveVacancy},on:{"setVacancy":_vm.changeActiveVacancy}}),_c('Vacancy',{attrs:{"vacancy":_vm.activeVacancy,"stage":_vm.activeStage}})],1),(_vm.hasActiveVacancy)?_c('div',{staticClass:"vacancy__folder__button"},[(_vm.activeStage < 4)?_c('button',{staticClass:"form__btn form__btn--fill",on:{"click":_vm.plusStage}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e(),(_vm.activeStage >= 4)?_c('a',{staticClass:"form__btn form__btn--fill",attrs:{"href":'mailto:hr@trinitymonsters.com?subject=Отклик на вакансию' +
            ' ' +
            _vm.activeVacancy.title}},[_vm._v(" откликнуться ")]):_vm._e()]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }