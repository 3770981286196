<template>
  <div class="form">
    <form class="form__in" @submit.prevent="submit" v-if="!success">
      <label class="form__label">
        {{ $t("form.name") }}
        <input
          class="form__field form__input"
          type="text"
          v-model="name"
          name="name"
          required
        />
      </label>

      <label class="form__label">
        {{ $t("form.email") }}
        <input
          class="form__field form__input"
          type="email"
          v-model="email"
          name="email"
          required
        />
      </label>

      <label class="form__label">
        {{ $t("form.message") }}
        <textarea
          class="form__field form__textarea"
          v-model="message"
          name="message"
          required
        ></textarea>
      </label>

      <label
        class="form__checkbox__label"
        :class="{ form__checkbox__error: checkError }"
      >
        <label
          id="checkbox"
          type="checkbox"
          @click="setCheckbox"
          class="form__checkbox"
        />
        <div style="position: relative; width: 0; height: 0">
          <label
            @click="checked = !checked"
            class="form__checkbox__checked"
            v-show="checked"
          />
        </div>
        <label class="form__checkbox__text">
          {{ $t("form.policy-agreement") }}
          <a
            href="https://docs.google.com/document/d/1ysYuV5crDE-L01K9qQE7Q5ZlyIz
tIet4JBzHuww0txE/edit?usp=sharing"
            class="form__checkbox__text__href"
            target="_blank"
          >
            {{ $t("form.policy-agreement-link") }}
          </a>
        </label>
      </label>

      <button class="form__btn form__btn--fill">{{ $t("form.send") }}</button>
    </form>

    <div class="form__in" v-if="success">
      <div class="form__success-text" v-html="$t('form.success')"></div>
      <div class="form__success-img">
        <Watcher />
      </div>
      <button class="form__btn" @click="reset">ок</button>
    </div>
  </div>
</template>

<script>
import Watcher from "@/components/Watcher";
export default {
  name: "Form",
  components: { Watcher },
  data() {
    return {
      name: null,
      email: null,
      message: null,
      success: false,
      checked: false,
      checkError: false
    };
  },
  methods: {
    async submit(event) {
      if (this.checked) {
        const data = new FormData(event.target);

        try {
          await this.axios.post("/orders", data);

          this.success = true;
        } catch (e) {
          this.reset();
        }
      } else {
        this.checkError = true;
      }
    },
    setCheckbox() {
      this.checked = !this.checked;
      if (this.checkError) this.checkError = !this.checkError;
    },
    reset() {
      this.success = false;
      this.name = "";
      this.email = "";
      this.message = "";
    }
  }
};
</script>
