<template>
  <div class="vacancy__folder__description">
    <PerfectScrollbar
      class="vacancy__folder__description__scroll-body"
      :options="{ minScrollbarLength: 173, suppressScrollX: true }"
      ref="rightVacancyScrollbar"
      infinite-wrapper
      :class="{
        vacancy__folder__description__active: hasVacancy,
        vacancy__folder__description__notActive: !hasVacancy
      }"
    >
      <div class="vacancy__description" v-if="hasVacancy">
        <div class="vacancy__description__message__group" v-if="stage >= 0">
          <div class="vacancy__description__message__group__icon">
            <img width="37px" height="44px" :src="vacancy.parent.icon" />
            <div class="vacancy__description__icon__title">
              {{ vacancy.parent.name }}
            </div>
          </div>
          <div class="vacancy__description__message">
            {{ vacancy.welcoming_message }}
          </div>
        </div>
        <div
          class="vacancy__description__message__group vacancy__description__message__group__reverse"
          v-if="stage >= 1"
        >
          <div class="vacancy__description__message__group__icon">
            <img width="37px" height="44px" :src="skullIcon" />
            <div class="vacancy__description__icon__title">
              Это ты
            </div>
          </div>
          <div class="vacancy__description__message">
            Рассказывай, рассказывай, рассказывай!!!
          </div>
        </div>
        <div class="vacancy__description__message__group" v-if="stage >= 2">
          <div class="vacancy__description__message__group__icon">
            <img width="37px" height="44px" :src="vacancy.parent.icon" />
            <div class="vacancy__description__icon__title">
              {{ vacancy.parent.name }}
            </div>
          </div>
          <div class="vacancy__description__message">
            <div class="vacancy__description__title">
              {{ vacancy.title }}
            </div>
            <div class="vacancy__description__skills">
              <Progressline
                v-for="option in vacancy.skills"
                v-bind:key="option.id"
                :title="option.title"
                :value="Number(option.value)"
                :type="'vacancy'"
              >
              </Progressline>
            </div>
            <div class="vacancy__description__expectations">
              <div class="vacancy__description__expectations__title">
                Что я жду от кандидата:
              </div>
              <div class="vacancy__description__expectations__text">
                {{ vacancy.expectations }}
              </div>
            </div>
            <div class="vacancy__description__become__team">
              <div class="vacancy__description__become__team__title">
                Как стать частью нашей команды:
              </div>
              <div class="vacancy__description__become__team__text">
                {{ vacancy.become_team }}
              </div>
            </div>
            <div class="vacancy__description__offer">
              <div class="vacancy__description__offer__title">
                Что мы предлагаем:
              </div>
              <div class="vacancy__description__offer__text">
                <ul>
                  <li>Мы — студия разработки приложений</li>
                  <li>Цифровых продуктов для управления B2B процессами.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          class="vacancy__description__message__group vacancy__description__message__group__reverse"
          v-if="stage >= 3"
        >
          <div class="vacancy__description__message__group__icon">
            <img width="37px" height="44px" :src="skullIcon" />
            <div class="vacancy__description__icon__title">
              Это ты
            </div>
          </div>
          <div class="vacancy__description__message">
            Я — точно тот, кто вам нужен.
          </div>
        </div>
        <div class="vacancy__description__message__group" v-if="stage >= 4">
          <div class="vacancy__description__message__group__icon">
            <img width="37px" height="44px" :src="vacancy.parent.icon" />
            <div class="vacancy__description__icon__title">
              {{ vacancy.parent.name }}
            </div>
          </div>
          <div class="vacancy__description__message">
            Супер! Нажимай кнопку «откликнуться».
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  </div>
</template>

<script>
import Progressline from "@/components/Progressline";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import skullIcon from "@/assets/images/icons/Skull.png";

export default {
  name: "vacancy",
  components: { Progressline, PerfectScrollbar },
  props: {
    vacancy: Object,
    stage: Number,
    activeVacancy: Boolean
  },
  watch: {
    stage() {
      this.scrollToBottom();
    }
  },
  computed: {
    hasVacancy() {
      return this.vacancy !== null;
    }
  },
  methods: {
    scrollToBottom() {
      if (this.stage > 2) {
        this.$refs.rightVacancyScrollbar.ps.update();
        this.$refs.rightVacancyScrollbar.$el.scrollTop = this.$refs.rightVacancyScrollbar.$el.scrollHeight;
        this.$refs.rightVacancyScrollbar.ps.update();
        setTimeout(() => {
          this.$refs.rightVacancyScrollbar.ps.update();
          this.$refs.rightVacancyScrollbar.$el.scrollTop = this.$refs.rightVacancyScrollbar.$el.scrollHeight;
          this.$refs.rightVacancyScrollbar.ps.update();
        }, 500);
        setTimeout(() => {
          this.$refs.rightVacancyScrollbar.ps.update();
          this.$refs.rightVacancyScrollbar.$el.scrollTop = this.$refs.rightVacancyScrollbar.$el.scrollHeight;
          this.$refs.rightVacancyScrollbar.ps.update();
        }, 1200);
      }
    }
  },
  data() {
    return {
      skullIcon
    };
  }
};
</script>
