<template>
  <div class="progressline" :class="{ progressline__vacancy: isVacancy }">
    <div class="progressline__title" v-if="title" :class="{ progressline__title__vacancy: isVacancy }">{{ title }}</div>
    <div class="progressline__value" :class="{ progressline__value__vacancy: isVacancy }">
      <div class="progressline__value-line" :style="{ width: `${value}%` }">
        {{ value }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "progressline",
  components: {},
  props: {
    title: String,
    value: Number,
    type: String
  },
  computed: {
    isVacancy() {
      if (this.type === 'vacancy') {
        return true
      }
      return false
    }
  },
  data() {
    return {};
  },
};
</script>
