<template>
  <div
    class="shortcut"
    :class="[
      'shortcut--' + shortcutId,
      { 'shortcut--top': topShortcut == shortcutId }
    ]"
    :style="{ top: position.y + 'px', left: position.x + 'px' }"
    @click.stop="click"
  >
    <a
      v-if="link && link.length"
      :href="link"
      target="_blank"
      class="shortcut__handle"
    ></a>
    <div v-else class="shortcut__handle"></div>

    <div
      class="shortcut__icon"
      :class="[
        'shortcut__icon--' + shortcutId,
        isListEmpty ? `shortcut__icon--${shortcutId}-empty` : null,
        hasCustomIcon ? 'shortcut__icon--custom' : null,
        hasCustomIcon && customImageLoaded
          ? 'shortcut__icon--custom-loaded'
          : null
      ]"
    >
      <img
        :src="icon"
        alt=""
        class="shortcut__icon-img"
        :class="{
          'shortcut__icon-img--hover-wait': hasCustomIcon && iconHover
        }"
        v-if="hasCustomIcon"
        @load="customImageLoaded = true"
      />

      <img
        :src="iconHover"
        alt=""
        class="shortcut__icon-img shortcut__icon-img--hover"
        v-if="hasCustomIcon && iconHover"
        @load="customImageLoaded = true"
      />

      <Trashicon
        :empty="isListEmpty"
        :cleaned="isListEmpty && dirty"
        v-if="isTrash"
      />
    </div>
    <div class="shortcut__label">{{ text }}</div>

    <template v-if="type == 'folder'">
      <draggable v-bind="dragOptions">
        <transition-group
          type="animation"
          name="shortcut__drop-in-"
          :class="dropClass"
          v-if="shortcutId === 'trash'"
        >
          <div
            class="shortcut__drop-in"
            v-for="icon in iconsList"
            :key="icon.id"
          >
            <Shortcut
              :text="getTranslation(icon.title)"
              :type="icon.type"
              :shortcutId="icon.id"
              :icon="icon.icon"
              :list="[]"
            />
          </div>
        </transition-group>
      </draggable>
    </template>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Trashicon from "@/components/Trashicon";

export default {
  name: "Shortcut",
  components: { draggable, Trashicon },
  props: {
    text: String,
    shortcutId: String,
    topShortcut: String,
    type: String,
    icons: Array,
    icon: String,
    iconHover: String,
    link: String,
    defaultPosition: {},
    list: Array,
    back: String
  },
  data() {
    return {
      customImageLoaded: false,
      dirty: false
    };
  },
  computed: {
    position() {
      return this.defaultPosition || { x: 0, y: 0 };
    },
    iconsList: {
      get() {
        return this.list;
      },
      set(value) {
        this.$emit("sendShortcutMove", this.shortcutId, value);
      }
    },
    hasCustomIcon() {
      return this.icon != null;
    },
    isListEmpty() {
      return !(this.list && this.list.length > 0);
    },
    isTrash() {
      return this.shortcutId == "trash";
    },
    dropClass() {
      return "shortcut__drop shortcut__drop--" + this.shortcutId;
    },
    dragOptions() {
      const isTrash = this.shortcutId === "trash";
      return {
        list: this.iconsList,
        group: {
          name: "group-icon-" + this.shortcutId,
          pull: false,
          put: true
        },
        animation: isTrash ? 600 : 0,
        class: isTrash ? "" : this.dropClass
      };
    }
  },
  watch: {
    iconsList(value) {
      if (value && value.length > 0) {
        this.dirty = true;
      }
    }
  },
  methods: {
    click() {
      this.$emit("dblclick");
      this.$emit("layerBack", this.back, this.shortcutId);
    }
  }
};
</script>
