<template>
  <footer
    class="taskbar"
    :class="['taskbar--style-' + theme, { 'taskbar--darkmode': darkmode }]"
  >
    <button class="taskbar__order" @click="$emit('showForm')">
      {{ $t("form.order") }}
    </button>

    <div class="taskbar__service">
      <div class="taskbar__battery">
        <img
          v-if="!darkmode"
          class="taskbar__battery-img"
          src="@/assets/images/battery-white.svg"
          alt
        />
        <img
          v-if="darkmode"
          class="taskbar__battery-img"
          src="@/assets/images/battery-black.svg"
          alt
        />
      </div>
      <button class="taskbar__theme" @click="$emit('toggleMode')">
        <Themeicon :darkmode="darkmode" />
      </button>
      <button class="taskbar__lang" v-on:click="changeLocale">
        {{ $i18n.locale }}
      </button>
      <div class="taskbar__time">{{ time }}</div>
    </div>
  </footer>
</template>

<script>
import Themeicon from "@/components/Themeicon";
export default {
  name: "Taskbar",
  components: { Themeicon },
  props: {
    theme: Number,
    darkmode: Boolean
  },
  data() {
    return {
      time: "",
      timer: null
    };
  },
  created() {
    this.updateTime();
    this.timer = setInterval(this.updateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateTime() {
      const currentDate = new Date();

      this.time =
        this.roundTime(currentDate.getHours()) +
        ":" +
        this.roundTime(currentDate.getMinutes());
    },
    roundTime(num) {
      return num < 10 ? `0${num}` : num;
    },
    changeLocale() {
      const lang = this.$i18n.locale == "en" ? "ru" : "en";
      this.$i18n.locale = lang;
    }
  }
};
</script>
